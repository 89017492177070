<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      장바구니<router-link to="" @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_8">
      <div class="top">
        <ul>
          <li>주문 전 장바구니 상품은 7일간 임시 보관됩니다.</li>
          <li>
            장바구니에 보관된 상품의 가격, 옵션 등 상품정보가 변경된 경우 주문이
            불가능합니다.
          </li>
        </ul>
      </div>
      <div class="middle">
        <div class="check_all">
          <label v-if="goodsList && parseInt(totalCnt) > 0"
            ><input type="checkbox" name="" v-model="isLocalAllCheck" />상품
            모두 선택</label
          >
        </div>
        <div class="list" v-if="goodsList">
          <div
            class="box"
            v-for="(goods, indexName) in goodsList"
            :key="indexName"
            :id="indexName"
          >
            <CartItemProvider
              :goods="goods"
              :indexName="indexName"
              v-if="goods.goods.length > 0"
            />
            <CartItem
              v-for="(item, idx) in goods.goods"
              :key="`${item.cart_option_seq}_${idx}`"
              :id="`${item.cart_option_seq}_${idx}`"
              :item="item"
              :goods="goods"
              :indexName="indexName"
              :idx="idx"
              :iniInfo="cartList.ini_info"
              @setPosition="setPosition"
            />
          </div>
        </div>
        <div class="list no_list" v-if="!goodsList || parseInt(totalCnt) <= 0">
          <p>장바구니에 담긴 상품이 없습니다.</p>
        </div>
      </div>
      <ul class="bottom" v-if="goodsList && parseInt(totalCnt) > 0">
        <li>선택한 상품 : 총 {{ totalCnt }}개</li>
        <li>총 상품 금액 : {{ won(totalAmount) }}원</li>
        <li>배송비 : {{ won(shippingPrice) }}원</li>
        <li>할인 예상 금액 : {{ won(totalSales) }}원</li>
        <li>
          총 주문 금액 :
          {{ won(totalAmount - totalSales + shippingPrice) }}원
        </li>
      </ul>
    </div>
    <!---->
    <div
      class="rn_my_empty_30"
      v-if="goodsList && parseInt(totalCnt) > 0"
    ></div>
    <!---->
    <div
      class="rn_my_box_btn yellow"
      v-if="goodsList && parseInt(totalCnt) > 0"
    >
      <div>
        <router-link to="" @click.native="order"
          >{{ totalRowCnt }}건 주문하기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CartItemProvider from "@/components/mypage/mycart/CartItemProvider";
import CartItem from "@/components/mypage/mycart/CartItem";
import MyOrderModify from "@/components/mypage/MyOrderModify";
import _orderBy from "lodash/orderBy";
export default {
  data() {
    return {
      type: this.$route.params.type,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    if (this.type) {
      if (this.type.toLowerCase() !== "modify") {
        this.$store.dispatch("order/dataClear");
      }
    } else {
      this.$store.dispatch("order/dataClear");
    }

    this.getCartList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      console.log(vm.prevRoute);
    });
  },
  components: {
    CartItemProvider,
    CartItem,
    MyOrderModify,
  },
  computed: {
    ...mapState("mypage", ["checkAuth"]),
    ...mapState("order", [
      "result",
      "msg",
      "cartList",
      "totalCnt",
      "totalAmount",
      "shippingPrice",
      "totalSales",
      "isAllCheck",
      "totalRowCnt",
      "position",
    ]),
    goodsList() {
      return this.cartList.shipping_group_list;
    },
    isLocalAllCheck: {
      get() {
        return this.isAllCheck;
      },
      set(check) {
        this.$store.dispatch("order/goodsChecked", {
          group: false,
          idx: false,
          check,
          isAll: true,
        });
      },
    },
    orderByData(data) {
      return _orderBy(data, "option_seq", "desc");
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getCartList() {
      await this.$store.dispatch("order/getCartList");
      if (parseInt(this.position) !== 0) {
        window.scrollTo(0, parseInt(this.position));
      }
      // if (!this.result) {
      //   this.$toast.default("장바구니에 상품이 존재하지 않습니다.");
      //   this.$router.push("/mypage/my_info");
      // }
    },
    won(str) {
      return this.$won(str);
    },
    setPosition(position) {
      this.$store.dispatch("order/setPosition", { position });
    },
    async order() {
      const order = [
        ...document.querySelectorAll(".info input[type=checkbox]:checked"),
      ];
      if (order.length <= 0) {
        this.$toast.default("1개 이상의 상품을 선택해주세요.");
        return false;
      }

      let formData = new FormData();

      formData.append("cart_version", 3);
      formData.append("nation", this.cartList.ini_info.nation);
      formData.append("mode", "choice");
      formData.append("kr_nation", this.cartList.ini_info.kr_nation);

      order.map((o) => {
        const cartOptionSeq = o.dataset.cartOptionSeq;
        const shipPossible = o.dataset.shipPossible;
        const shipNation = o.dataset.shipNation;
        const goodsSeq = o.dataset.goodsSeq;

        formData.append("cart_option_seq[]", cartOptionSeq);
        formData.append(`ship_possible[${cartOptionSeq}]`, shipPossible);
        formData.append(`ship_nation[${cartOptionSeq}]`, shipNation);
      });

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      await this.$store.dispatch("order/choiceOne", {
        formData,
        mode: !this.isLocalAllCheck ? "choice" : null,
      });

      if (!this.$store.state.order.result) {
        this.$toast.default(this.$store.state.order.msg);
        return false;
      }
      if (this.isLocalAllCheck) {
        this.$router.push(`/order_payment/cart/cart`);
        return false;
      } else {
        this.$router.push(`/order_payment/cart/choice`);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &.no_list {
    text-align: center;
    padding: 40px 0 0 0;
    p {
      font-size: 12px;
    }
  }
}
</style>
