<template>
  <div class="info">
    <div class="left">
      <label
        ><input
          type="checkbox"
          name=""
          v-model="isChecked"
          :data-cart-option-seq="item.cart_option_seq"
          :data-ship-possible="this.goods.ship_possible"
          :data-ship-nation="this.goods.cfg.baserule.delivery_nation"
          :data-goods-seq="item.goods_seq"
          :id="`goods_${item.goods_seq}`"
      /></label>
    </div>
    <div class="right">
      <div class="right_sec_1">
        <div class="img">
          <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
        </div>
        <div class="txt">
          <h3>{{ item.goods_name }}</h3>
          <h4>
            {{
              won(parseInt(item.sales.result_price) + parseInt(addGoodsPrice))
            }}원
            <span
              v-if="
                parseInt(item.price) * parseInt(item.ea) >
                parseInt(item.sales.result_price)
              "
            >
              {{ won(parseInt(item.price) * parseInt(item.ea)) }}원
            </span>
          </h4>
          <p class="shipping_txt">(배송비 제외)</p>
          <p v-if="parseInt(goods.provider_seq) == 1">
            {{ goods.shipper_name }}
          </p>
          <p v-else>{{ goods.shipper_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</p>
        </div>
      </div>
      <ul class="right_sec_2">
        <li>선택 : {{ item.goods_name }} {{ item.ea }}개</li>
        <li>
          가격 :
          {{ won(parseInt(item.sales.result_price)) }}원
        </li>
        <li v-if="parseInt(item.sales.total_sale_price) > 0">
          할인 :
          {{ won(parseInt(item.sales.total_sale_price)) }}원
        </li>
        <li v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</li>
        <li v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</li>
        <li v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</li>
        <li v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</li>
        <li v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</li>
        <li
          v-for="(sub, idx) in item.cart_suboptions"
          :key="`${sub.suboption_seq}_${idx}`"
          :id="`${sub.suboption_seq}_${idx}`"
        >
          추가 :
          {{ sub.suboption_title }}({{ sub.suboption }}) {{ sub.ea }}개
          {{ won(parseInt(sub.price) * parseInt(sub.ea)) }}원
        </li>
        <li>배송비 : {{ shippingCost(goods) }}</li>
        <!-- <li>도착 예정일 : 09.09 (토)</li> -->
      </ul>
      <ul class="right_sec_3">
        <li>
          <router-link to="" @click.native="eaOrder($event)"
            >주문하기</router-link
          >
        </li>
        <li>
          <router-link to="" @click.native="orderModify">주문 수정</router-link>
        </li>
        <li>
          <router-link to="" @click.native="deleteCart">삭제하기</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    goods: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    iniInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    idx: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    isChecked: {
      get() {
        return this.item.checked;
      },
      set(check) {
        this.$store.dispatch("order/goodsChecked", {
          group: this.indexName,
          idx: this.idx,
          check,
          isAll: false,
        });
      },
    },
    addGoodsPrice() {
      let price = 0;
      if (this.item.cart_suboptions) {
        this.item.cart_suboptions.map((sub) => {
          price += parseInt(sub.sale_price) * parseInt(sub.ea);
        });
        return price;
      }
      return price;
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    orderModify() {
      this.$emit("setPosition", window.scrollY);
      this.$router.push(`/mypage/order_modify/${this.item.cart_option_seq}`);
    },
    deleteCart() {
      this.$confirm("상품을 장바구니에서 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("order/deleteCart", {
            cart_option_seq: this.item.cart_option_seq,
            key_name: this.indexName,
          });
          this.$toast.default(this.$store.state.order.msg);
        })
        .catch(() => {
          return false;
        });
    },
    won(str) {
      return this.$won(str);
    },
    shippingCost({ grp_shipping_price, ship_possible }) {
      if (parseInt(grp_shipping_price) > 0) {
        return `${this.$won(grp_shipping_price)}원`;
      } else {
        if (ship_possible === "Y") {
          return "무료";
        } else {
          return "배송불가";
        }
      }
    },
    async eaOrder(event) {
      const cartOptionSeq = event.target
        .closest(".info")
        .querySelector("input[type=checkbox]").dataset.cartOptionSeq;
      const shipPossible = event.target
        .closest(".info")
        .querySelector("input[type=checkbox]").dataset.shipPossible;
      const shipNation = event.target
        .closest(".info")
        .querySelector("input[type=checkbox]").dataset.shipNation;
      const goodsSeq = event.target
        .closest(".info")
        .querySelector("input[type=checkbox]").dataset.goodsSeq;

      //console.log(cartOptionSeq, shipPossible, shipNation);
      let formData = new FormData();

      formData.append("cart_version", 3);
      formData.append("nation", this.iniInfo.nation);
      formData.append("mode", "choice");
      formData.append("kr_nation", this.iniInfo.kr_nation);
      formData.append("cart_option_seq[]", cartOptionSeq);
      formData.append(`ship_possible[${cartOptionSeq}]`, shipPossible);
      formData.append(`ship_nation[${cartOptionSeq}]`, shipNation);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      await this.$store.dispatch("order/choiceOne", {
        formData,
        mode: "choice",
      });
      if (!this.$store.state.order.result) {
        this.$toast.default(this.$store.state.order.msg);
        return false;
      }
      this.$router.push(`/order_payment/cart/choice`);
    },
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_8 .middle .list .box .info .right .right_sec_1 .txt h4 {
  margin-bottom: 5px;
}
.shipping_txt {
  font-size: 11px;
  margin-bottom: 10px;
  line-height: 11px;
}
</style>
