<template>
  <div class="check">
    <label
      ><input type="checkbox" name="" v-model="isChecked" />{{
        goods.shipper_name
      }}</label
    >
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    isChecked: {
      get() {
        return this.goods.checked;
      },
      set(check) {
        this.$store.dispatch("order/goodsChecked", {
          group: this.indexName,
          idx: false,
          check,
          isAll: false,
        });
      },
    },
  },
};
</script>

<style></style>
